export default [
    {
        path: '/course/:id/module/:moduleId/edit',
        name: 'modules-edit',
        component: () => import('@/views/modules/courses/modules-edit/ModulesEdit.vue'),
        meta: {
            pageTitle: 'Módulo',
            breadcrumb: [
                {
                    text: 'Editar Módulo',
                    active: true
                }
            ]
        }
    },   
    {
        path: '/course/module/:id/exam/create',
        name: 'exam-create',
        component: () => import('@/views/modules/courses/modules-edit/exams-tab/ExamListAddNew.vue'),
        meta: {
            pageTitle: 'Módulo',
            breadcrumb: [
                {
                    text: 'Crear Examen',
                    active: true
                }
            ]
        }
    },  
    {
        path: '/course/module/:id/exam/edit',
        name: 'exam-edit',
        component: () => import('@/views/modules/courses/modules-edit/exams-tab/ExamEditInformation.vue'),
        meta: {
            pageTitle: 'Módulo',
            breadcrumb: [
                {
                    text: 'Editar Examen',
                    active: true
                }
            ]
        }
    },  
]