export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Inicio',
            breadcrumb: [
                {
                    text: 'Inicio',
                    active: true
                }
            ]
        }
    }
]