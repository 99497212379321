export default [
    {
        path: '/questions',
        name: 'questions',
        component: () => import('@/views/modules/questions/questions-list/QuestionsList.vue'),
        meta: {
            pageTitle: 'Banco de preguntas',
            breadcrumb: [
                {
                    text: 'Banco de preguntas',
                    active: true
                }
            ]
        }
    },
    {
        path: '/questions/store',
        name: 'questions-store',
        component: () => import('@/views/modules/questions/questions-list/QuestionListAddNew.vue'),
        meta: {
            pageTitle: 'Agregar pregunta',
            breadcrumb: [
                {
                    text: 'Agregar pregunta',
                    active: true
                }
            ]
        }
    },
    {
        path: '/questions/:id/edit',
        name: 'questions-edit',
        component: () => import('@/views/modules/questions/questions-edit/QuestionsEdit.vue')
    },
]