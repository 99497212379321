export default [
    {
        path: '/courses',
        name: 'courses',
        component: () => import('@/views/modules/courses/courses-list/CoursesList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Cursos',
            breadcrumb: [
                {
                    text: 'Lista de Cursos',
                    active: true
                }
            ]
        }
    },    
    {
        path: '/courses/create',
        name: 'courses-create',
        component: () => import('@/views/modules/courses/courses-list/CourseListAddNew.vue'),
        meta: {
            pageTitle: 'Cursos',
            breadcrumb: [
                {
                    text: 'Agregar Nuevo Curso',
                    active: true
                }
            ]
        }
    },
    {
        path: '/courses/:id/edit',
        name: 'courses-edit',
        component: () => import('@/views/modules/courses/courses-edit/CoursesEdit.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Cursos',
            breadcrumb: [
                {
                    text: 'Editar Curso',
                    active: true
                }
            ]
        }
    }, 
    {
        path: '/courses/qualifications',
        name: 'courses-qualifications',
        component: () => import('@/views/modules/courses/courses-qualifications/CoursesQualificationsList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Cursos',
            breadcrumb: [
                {
                    text: 'Lista de calificaciones',
                    active: true
                }
            ]
        }
    }, 
]