export default [
    {
        path: '/account-setting',
        name: 'account-setting',
        component: () => import('@/views/account/AccountSetting.vue'),
        meta: {
            pageTitle: 'Mi perfil',
            breadcrumb: [
                {
                    text: 'Configuraciones',
                    active: true
                }
            ]
        }
    }
]