export default [
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import('@/views/modules/statistics/Statistics.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Estadística',
            breadcrumb: [
                {
                    text: 'Estadística',
                    active: true
                }
            ]
        }
    }
]