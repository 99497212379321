export default [
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/modules/users/users-list/UsersList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Usuarios',
            breadcrumb: [
                {
                    text: 'Lista de Usuarios',
                    active: true
                }
            ]
        }
    },
    {
        path: '/users/:id/edit',
        name: 'users-edit',
        component: () => import('@/views/modules/users/users-edit/UsersEdit.vue'),
        meta: {
            pageTitle: 'Usuarios',
            breadcrumb: [
                {
                    text: 'Editar usuario',
                    active: true
                }
            ]
        }
    },
]