import Vue from 'vue';
import VueRouter from 'vue-router';


// Libs
import { canNavigate } from '@/libs/acl/routeProtection';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils';


// Routes
import home from './routes/home';
import login from './routes/authentication/login';
import speakers from './routes/speakers';
import customers from './routes/customers';
import users from './routes/users';
import account from './routes/account';
import miscellaneous from './routes/miscellaneous';
import responsibles from './routes/responsibles';
import courses from './routes/courses';
import questions from './routes/questions';
import modules from './routes/modules';
import statistics from './routes/statistics';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        ...home,
        ...customers,
        ...responsibles,
        ...speakers,
        ...users,
        ...login,
        ...account,
        ...questions,
        ...miscellaneous,
        ...courses,
        ...modules,
        ...statistics,
        {
            path: '*',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full'
            }
        }
    ]
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'login' });
        
        // If logged in => not authorized
        return next({ name: 'misc-not-authorized' });
    }
    
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData();
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    
    return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
