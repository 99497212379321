export default [
    {
        path: '/responsibles',
        name: 'responsibles',
        component: () => import('@/views/modules/responsibles/responsibles-list/ResponsiblesList.vue'),
        meta: {
            pageTitle: 'Responsables',
            breadcrumb: [
                {
                    text: 'Lista de Responsables',
                    active: true,
                }
            ]
        }
    },
    {
        path: '/responsibles/:id/edit',
        name: 'responsibles-edit',
        component: () => import('@/views/modules/responsibles/responsibles-edit/ResponsiblesEdit.vue'),
        meta: {
            pageTitle: 'Responsables',
            breadcrumb: [
                {
                    text: 'Editar Responsable',
                    active: true,
                }
            ]
        }
    },
]