export default [
    {
        path: '/customers',
        name: 'customers',
        component: () => import('@/views/modules/customers/customers-list/CustomersList.vue'),
        meta: {
            pageTitle: 'Clientes',
            breadcrumb: [
                {
                    text: 'Lista de Clientes',
                    active: true
                }
            ]
        }
    },
    {
        path: '/customers/:id/edit',
        name: 'customers-edit',
        component: () => import('@/views/modules/customers/customers-edit/CustomersEdit.vue'),
        meta: {
            pageTitle: 'Clientes',
            breadcrumb: [
                {
                    text: 'Editar Cliente',
                    active: true
                }
            ]
        }
    },
]