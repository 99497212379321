export default [
    {
        path: '/speakers',
        name: 'speakers',
        component: () => import('@/views/modules/speakers/speakers-list/SpeakersList.vue'),
        meta: {
            pageTitle: 'Expositores',
            breadcrumb: [
                {
                    text: 'Lista de Expositores',
                    active: true
                }
            ]
        }
    },
    {
        path: '/speakers/:id/edit',
        name: 'speakers-edit',
        component: () => import('@/views/modules/speakers/speakers-edit/SpeakersEdit.vue'),
        meta: {
            pageTitle: 'Expositores',
            breadcrumb: [
                {
                    text: 'Editar Expositor',
                    active: true
                }
            ]
        }
    },
]